<template>
  <v-breadcrumbs :items="items" class="mb-0 pb-1">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        class="poppins f14 fw600 text-capitalize"
        :to="item.to"
        link
        exact
        :disabled="item.disabled"
      >
        <div :class="[item.disabled && 'secondary--text', item.hasOwnProperty('link') && (item.link && 'primary--text')]">
          {{ item.text }}
        </div>
      </v-breadcrumbs-item>
    </template>
    <template v-slot:divider>
      <v-icon size="20">mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: {
    items: {
        type: Array,
        default: []
    },
  },
};
</script>

<style></style>
